import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@chakra-ui/react';
import { usePublicView } from './usePublicView';
import {
  getChartConfigFromChartType,
  InsightChartConfig,
} from '../../utils/insightCharts';
import { ViewChartData } from './types';
import { colorsSortedByKey } from '../../utils/routes';
import InsightChart from '../../components/InsightChart';
import useMemoChartDataFromSiteInsight from '../../hooks/useMemoChartDataFromSiteInsight';
import EmptyInsightChart from '../../components/SiteInsightChart/EmptyInsightChart';

interface PublicChartProps {
  viewChartData: ViewChartData;
}

export default function PublicChart({ viewChartData }: PublicChartProps) {
  const { formatMessage } = useIntl();
  const { routes, viewConfig } = usePublicView();

  const chartConfig = useMemo(() => {
    if (viewChartData) {
      return getChartConfigFromChartType(
        viewChartData.insightType,
        { siteUnit: viewConfig?.siteUnit ?? 'METRIC' },
        formatMessage
      );
    }
    return {
      chartType: 'line',
      title: 'Chart data not loaded',
    } as InsightChartConfig;
  }, [viewChartData, formatMessage, viewConfig?.siteUnit]);

  const routesColorsById = useMemo(
    () => colorsSortedByKey(routes || [], 'routeId'),
    [routes]
  );

  const hiddenRoutes = useMemo(
    () =>
      routes
        .filter(({ selected }) => !selected)
        .map((route) => route.routeId)
        .map(String),
    [routes]
  );

  const routesWithTrackId = useMemo(
    () =>
      viewChartData.routes.map((route) => ({
        ...route,
        trackId: route.routeId.toString(),
        // transforming from old SiteInsightData to ViewData:
        items: route.items.map((item) => ({
          value: item.value === undefined ? null : item.value,
          date: item.date * 1000,
        })),
      })),
    [viewChartData.routes]
  );

  const { isDataEmpty, isDataHeavy, formattedChartSeries } =
    useMemoChartDataFromSiteInsight(
      routesWithTrackId,
      hiddenRoutes,
      routesColorsById,
      chartConfig.chartType
    );

  return (
    <Box
      backgroundColor="white"
      borderRadius="md"
      boxShadow="sm"
      mb={8}
      minHeight="400px"
      overflow="hidden"
    >
      {isDataEmpty ? (
        <EmptyInsightChart title={chartConfig.title} />
      ) : (
        <InsightChart
          timeZone={viewConfig?.siteTimeZone || 'Pacific/Auckland'}
          chartConfig={chartConfig}
          chartSeries={formattedChartSeries}
          showContextMenu={false}
          shouldAnimate={!isDataHeavy}
          startTime={viewChartData.startTime * 1000}
          endTime={viewChartData.endTime * 1000}
        />
      )}
    </Box>
  );
}
